import { AppShell, Container, Header, Title } from "@mantine/core";
import { HeaderAction } from "./components/Header";
import { FeaturesCards } from "./components/Features";
import { Pricing } from "./components/PricingTable";

function App() {
  const headerLinks = [
    {
      link: "/home",
      label: "Home",
      links: [
        {
          link: "/home/about",
          label: "About",
        },
        {
          link: "/home/contact",
          label: "Contact",
        },
      ],
    },
    {
      link: "/products",
      label: "Products",
      links: [
        {
          link: "/products/featured",
          label: "Featured",
        },
        {
          link: "/products/sale",
          label: "Sale",
        },
      ],
    },
  ];

  return (
    <AppShell
      padding="md"
      header={
        <Header height={60} p="xs">
          {<HeaderAction links={headerLinks} />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {
        <>
          <Container>
            <FeaturesCards />
          </Container>
          <Container>
            <Title order={1}>Pricing</Title>
            <Pricing/>
          </Container>
        </>
      }
    </AppShell>
  );
}

export default App;

import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import { IconLockAccess, IconEyeX, IconCookie, IconMoodCheck } from "@tabler/icons-react";

const mockdata = [
  {
    title: "Anonymity Ensured",
    description:
      "SpeakFreely enables your team members to share their thoughts and ideas anonymously, creating a safe space for open communication and fostering a culture of trust.",
    icon: IconLockAccess,
  },
  {
    title: "Moderation Tools",
    description:
      "Our advanced moderation tools prevent misuse, ensuring that anonymity is not exploited and maintaining a positive work environment.",
    icon: IconEyeX,
  },
  {
    title: "Inclusivity Boost",
    description:
      "By giving everyone the chance to express themselves freely, SpeakFreely helps build a more inclusive and diverse workspace, fostering creativity and innovation.",
    icon: IconCookie,
  },
  {
    title: "Feedback and Recognition",
    description:
      "Encourage your team members to provide and receive constructive feedback, promoting personal and professional growth.",
    icon: IconMoodCheck,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export function FeaturesCards() {
  const { classes, theme } = useStyles();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">
      <Title order={2} className={classes.title} ta="center" mt="sm">
        Empower every voice in your team
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        Our mission is to create an inclusive and diverse workspace where
        everyone can share their ideas, opinions, and concerns without fear.
      </Text>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

export interface UserProfile {
    name: string;
    teamActive: boolean;
    teamName: string,
}

interface AuthContextData {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  userProfile: UserProfile | null;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get<UserProfile>('/api/profile');
        if (response.status === 200) {
          setIsLoggedIn(true);
          setUserProfile(response.data);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          setIsLoggedIn(false);
          setUserProfile(null);
        }
      }
    };
    checkLoginStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, userProfile, setUserProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
